import React from "react";
import { Fade } from "@chakra-ui/transition";
import { Box, Flex, Text } from "@chakra-ui/react";
import { CustomButton } from "../customButton";
import RenderServiceContentByType from "./virtualTour/renderServiceContentByType";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

function ServiceDetail({ service, selectedTabIndex }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return (
    <Fade key={service.id} in={true} transition={{ enter: { duration: 2 } }}>
      <Flex
        py={"10vh"}
        direction={{ base: "column", md: "row" }}
        gap={"20px"}
        alignItems="center"
      >
        <Box
          flex={{ base: 3, lg: 4 }}
          order={{ base: 2, md: 1 }}
          height={{ base: "100%", md: "400px", lg: "450px" }}
          position="relative"
        >
          {/* Services content element, renders content acording to content type set in backend */}
          <RenderServiceContentByType object={service.attributes} />
        </Box>

{/* Information box */}
        <Box
          flex="2"
          order={{ base: 1, md: 2 }}
          align={"left"}
          justifyContent={"center"}
          bg={"portfolioBg"}
          height={{ base: "100%", md: "400px", lg: "450px" }}
          p={8}
          boxShadow={"shadowRenderServiceContentByType"}
        >
          <Text fontSize="3xl" mb={4}>
            {service.attributes.title}
          </Text>
          <Text fontSize="md"> {service.attributes.description}</Text>

          <CustomButton
            mt={6}
            as={RouterLink}
            to={`/${language}/${t("routes.services")}#${selectedTabIndex}`}
          >
            {t("buttons.readMore")}
          </CustomButton>
        </Box>
      </Flex>
    </Fade>
  );
}

export default ServiceDetail;
