import { Box } from "@chakra-ui/react";

export default function SpacerLine({ color, customGradient }) {
  return (
    <Box
      h={"1px"}
      bgGradient={
        customGradient
          ? customGradient
          : `linear(to right, transparent 0% ,${color} 50%, transparent 100%)`
      }
      w={"100%"}
    />
  );
}
