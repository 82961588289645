import React from "react";

import ContactForm from "../components/contactForm";
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";
import { containerW } from "../utils/globalVars";
import Social from "../components/elements/social";
import { useTranslation } from "react-i18next";
import CompanyInfo from "../components/elements/companyInfo";
import PageSEO from "../components/elements/pageSEO";
import { useLocation } from "react-router-dom";

export default function Contact() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Website location
  const location = useLocation();

  // Locale
  const { t } = useTranslation();

  return (
    <Box>
      {/* Page meta information */}
      <PageSEO page={"contact"} />
      {/* Page meta information */}

      <Container maxW={containerW}>
        <Grid
          py={"50px"}
          templateColumns={{ base: "1fr", md: "2fr 4fr" }}
          gap={3}
        >
          <GridItem>
            <Box h="100%" w="100%">
              <Flex
                alignItems="center"
                justifyContent={{ base: "center", md: "flex-start" }}
                pb={5}
              >
                <Heading fontSize={"2xl"}>{t("contact-page.hello")}</Heading>
              </Flex>
              <Flex
                alignItems="center"
                justifyContent={{ base: "center", md: "flex-start" }}
                my={5}
              >
                <CompanyInfo fontSize={"md"} iconSize={"22px"} spacing={4} />
              </Flex>
              {isMobile ? (
                <Box
                  my={10}
                  h={"1px"}
                  w={"100%"}
                  bgGradient="linear(to-r, transparent, mainAc, transparent)"
                />
              ) : (
                <Box
                  marginBottom={5}
                  marginTop={5}
                  h={"1px"}
                  w={"60%"}
                  bgGradient="linear(to-r,mainAc, transparent)"
                />
              )}
              <Flex
                alignItems="center"
                justifyContent={{ base: "center", md: "flex-start" }}
                pb={5}
              >
                <Social size={"24px"} />
              </Flex>
            </Box>
          </GridItem>
          <GridItem h={"100%"}>
            <Box
              // bg={"portfolioBg"}
              h="100%"
              w="100%"
            >
              <Box>
                <ContactForm width="100%" />
              </Box>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
}
