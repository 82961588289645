import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderLink from "./headerLink";
import { HStack, StackDivider, Box, VStack } from "@chakra-ui/react";
import LanguageSwitcher from "./languageSwitcher";
import { mainMenuLinks } from "../../utils/menus";
import { scrollToTop } from "../../utils/globalFunctions";

export default function NavigationLinks({ onClose, isMobile }) {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const isCurrentLocation = (path) => {
    return location.pathname === `/${language}${path}`;
  };

  const linkComponent = () => (
    <>
      {mainMenuLinks.map((link, index) => (
        <HeaderLink
          key={index}
          isCurrentLocation={isCurrentLocation(`/${t(link.route)}`)}
          content={t(link.label).toUpperCase()}
          to={
            link.route === "" ? `/${language}` : `/${language}/${t(link.route)}`
          }
          onClick={() => {
            onClose();
            scrollToTop();
          }}
          isMobile={isMobile}
        />
      ))}
    </>
  );

  //  Navigation links display: mobile or monitor
  return isMobile ? (
    <VStack
      align="start"
      spacing={0}
      divider={<StackDivider borderColor="lightGray" />}
    >
      <Box h={"20px"}></Box>
      {linkComponent()}
      <LanguageSwitcher onClose={onClose} />
    </VStack>
  ) : (
    <HStack spacing="30px" h={"100%"}>
      {linkComponent()}

      <LanguageSwitcher onClose={onClose} />
    </HStack>
  );
}
