import React from "react";
import { Box, Container } from "@chakra-ui/react";
import SectionLanding from "../components/sections/sectionLanding";
import { useTranslation } from "react-i18next";
import SectionTitle from "../components/sections/sectionTitle";
import { CustomButton } from "../components/customButton";
import { Link, useLocation } from "react-router-dom";
import ServicesHome from "../components/services/servicesHome";
import PageSEO from "../components/elements/pageSEO";
import PortfolioSectionHome from "../components/homepage/portfolioSectionHome";

export default function Homepage() {
  // Website location
  const location = useLocation();

  // Localization settings
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  return (
    <>
      {/* Page meta information */}
      <PageSEO page={"home"} />
      {/* Page meta information */}
      <Box h={"100vh"}>
        <SectionLanding />
      </Box>

      <SectionTitle title={t("sectionTitle.servicesHome")} />
      <ServicesHome />
      <Container maxW="100vw" bg={"portfolioBg"}>
        <SectionTitle title={t("sectionTitle.portfolioHome")} seperator />

        <PortfolioSectionHome />

        <Link
          to={`/${currentLang}/${t("routes.portfolio")}`}
          onClick={() => window.scrollTo(0, 0)}
        >
          <CustomButton mt={5} mb={10}>
            {t("buttons.moreWorks")}
          </CustomButton>
        </Link>
      </Container>
    </>
  );
}

// CAN SELECT MANY CATEGORIES AT THE TIME - MAY BE USEFULL IN FUTURE

// const filteredPortfolios = useMemo(() => {
//   if (selectedCategories.length === 0) {
//     return portfolioItems;
//   }
//   return portfolioItems?.filter((portfolioItem) =>
//     selectedCategories.includes(portfolioItem.attributes.category.data.id)
//   );
// }, [selectedCategories, portfolioItems]);
