import React from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import Icon from "./icon";

export default function RightArrowIcon({ handleChange, size }) {
  return (
    <Icon icon={ChevronRightIcon} onClick={handleChange} justify="end"
size={size}

    ></Icon>
  );
}
