import React from "react";
import { Box, keyframes } from "@chakra-ui/react";
import { motion } from "framer-motion";
import LoadingSpinner from "../loadingSpinner";

export default function ContentLoader({h="200px", loading}) {
  const animationKeyframes = keyframes`
    0% { opacity: 0.5}
    25% { opacity: 0.25}
    50% {opacity: 0.8 }
    75% {opacity: 0.2 }
    100% { opacity: 0.5 }
  `;
  const animationKeyframesBox = keyframes`
    0% { left: 20%}
    50% { left: 80% }
    100% { left: 20% }
  `;

  const animation = `${animationKeyframes} 3s ease-in-out infinite`;
  const animationBgBox = `${animationKeyframesBox} 2s ease-in-out infinite`;

  return (
    <Box
      position={"relative"}
      as={motion.div}
      bgGradient="linear-gradient(90deg, portfolioBg 0%, black 50%, portfolioBg 100%)"
      animation={animation}
      h={h}
      // bg={"portfolioBg"}
      borderRadius={"10px"}
    >
      <Box
        position={"absolute"}
        as={motion.div}
        animation={animationBgBox}
        transform="translate(-50%)"
        h={"100%"}
        w={"30%"}
        bgGradient="linear-gradient(90deg, rgba(255, 0, 0, 0) 0%, mainAcBgServiceCards 50%, rgba(255, 0, 0, 0) 100%)"
      ></Box>
      <LoadingSpinner loading={loading} />
    </Box>
  );
}
