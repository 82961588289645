import { Box, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import ImageWrapper from "../elements/imageWrapper";
import { pano, play } from "../../utils/media";

export default function PortfolioItem({ portfolio, openModal }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="masonry-item"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ImageWrapper
        imgUrl={`${portfolio.attributes.main_image.data.attributes.url}`}
        alt={`${process.env.REACT_APP_BACKEND_URL}${portfolio.attributes.main_image.data.attributes.alternativeText}`}
        onClick={() => {
          openModal(portfolio.id);
        }}
        isActive
        loaderH={"200px"}
        icon={portfolio.attributes.content_type.map((contentType) => {
          switch (contentType.__typename) {
            case "ComponentContentTypesPortfolioVirtualTour":
              return (
                <Box
                  key={portfolio.id}
                  opacity={isHovered ? "1" : "0.75"}
                  transition="opacity 0.3s ease-in-out"
                >
                  <Image
                    position={"absolute"}
                    top="50%"
                    left="50%"
                    opacity={"75%"}
                    transform="translate(-50%, -50%)"
                    h={"100px"}
                    src={pano}
                    alt="360 Logo"
                  />
                </Box>
              );
            case "ComponentContentTypesPortfolioSingleVideo":
              return (
                <Box
                  key={portfolio.id}
                  opacity={isHovered ? "1" : "0.75"}
                  transition="opacity 0.3s ease-in-out"
                >
                  <Image
                    position={"absolute"}
                    top="50%"
                    left="50%"
                    opacity={"75%"}
                    transform="translate(-50%, -50%)"
                    h={"100px"}
                    src={play}
                    alt="360 Logo"
                  />
                </Box>
              );
            default:
              return null;
          }
        })}
      />
    </div>
  );
}
