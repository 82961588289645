import { Box, Link } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export default function FooterLink({
  isMobile,
  onClick,
  to,
  isCurrentLocation,
  content,
}) {
  return isMobile ? (
    <Box>
      <Link
        onClick={onClick}
        to={to}
        as={RouterLink}
        fontSize="sm"
        color={isCurrentLocation ? "mainAc" : "mainWhite"}
        borderLeftColor={isCurrentLocation ? "mainAc" : "transparent"}
        h="50px"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        // Works on pc / mobile not
        // _hover={{
        //   color: "mainAc",
        //   borderLeftColor: "mainAc",
        // }}
        // Works on mobile / PC not
        _hover={{
          textDecoration: "none",
          _focus: { boxShadow: "none" },
        }}
      >
        {content}
      </Link>
    </Box>
  ) : (
    <Box>
      <Link
        onClick={onClick}
        fontSize="sm"
        as={RouterLink}
        to={to}
        color={isCurrentLocation ? "mainAc" : "mainWhite"}
        h="100%"
        display={"flex"}
        justifyContent={"left"}
        _hover={{
          color: "mainAc",
          borderBottomColor: "mainAc",
        }}
      >
        {content}
      </Link>
    </Box>
  );
}
