import { ApolloProvider } from "@apollo/client";
import client from "./utils/apolloClient";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { ChakraProvider } from "@chakra-ui/react";
import Homepage from "./pages/Homepage.js";
import Services from "./pages/Services.js";
import Contact from "./pages/Contact.js";
import Policies from "./pages/Policies";
import Header from "./components/header/Header.js";
import MainLayout from "./components/mainLayout";
import CookieConsent from "./components/cookieConsent";
import { theme } from "./utils/globalVars";
import "./App.css";
import Portfolio from "./pages/Portfolio";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const navigate = useNavigate();
  // const location = useGeoLocation();
  const { t, i18n } = useTranslation();


  useEffect(() => {
    let lang;
    if (window.location.pathname.includes("/en")) {
      lang = "en";
      i18n.changeLanguage(lang);
      Cookies.set("i18next", lang);
    }
    if (window.location.pathname.includes("/lt")) {
      lang = "lt";
      i18n.changeLanguage(lang);
      Cookies.set("i18next", lang);
    }
    if (window.location.pathname === "/") {
      lang = Cookies.get("i18next");
      // if (!lang && location.country) {
      //   console.log("iskvietimas")
      //   const countryCode = location.country.toLowerCase();
      //   i18n.changeLanguage(countryCode);
      //   navigate(`/${countryCode}`);
      // }
      if (lang) {
        navigate(`/${i18n.language}`);
      }
    }
  }, [i18n]);

  return (
    <ChakraProvider theme={theme}>
      <ApolloProvider client={client}>
        <HelmetProvider>
          <div className="App">
            <MainLayout>
              <Header></Header>
              <Routes>
                {/* <Route index path="/" element={<Homepage />} /> */}
                <Route index path="/:lang" element={<Homepage />} />
                <Route
                  path={`/:lang/${t("routes.portfolio")}`}
                  element={<Portfolio />}
                />
                <Route
                  path={`/:lang/${t("routes.services")}`}
                  element={<Services />}
                />
                <Route
                  path={`/:lang/${t("routes.contact")}`}
                  element={<Contact />}
                />
                <Route
                  path={`/:lang/${t("routes.policies")}`}
                  element={<Policies />}
                />
              </Routes>
              <CookieConsent />
            </MainLayout>
          </div>
        </HelmetProvider>
      </ApolloProvider>
    </ChakraProvider>
  );
}

export default App;
