import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  Flex,
  Text,
  Checkbox,
  HStack,
  Link,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { CustomButton } from "./customButton";

const SUBMIT_CONTACT_FORM = gql`
  mutation SubmitContactForm(
    $name: String!
    $surname: String!
    $email: String!
    $company: String!
    $phone: String!
    $message: String!
  ) {
    createMessage(
      data: {
        name: $name
        surname: $surname
        email: $email
        company: $company
        phone: $phone
        message: $message
      }
    ) {
      data {
        attributes {
          name
          surname
          email
          company
          phone
          message
        }
      }
    }
  }
`;
export default function ContactForm() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [submitContactForm, { data, loading, error }] =
    useMutation(SUBMIT_CONTACT_FORM);

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const toast = useToast();

  const handleCheckboxChange = (event) => setIsChecked(event.target.checked);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await submitContactForm({
        variables: { name, surname, email, company, phone, message },
      });

      if (error) {
        throw new Error(error);
      }

      toast({
        title: "Message sent",
        description:
          "We've received your message and will get back to you shortly.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setIsChecked(false);
      setName("");
      setEmail("");
      setMessage("");
      setSurname("");
      setCompany("");
      setPhone("");
    } catch (err) {
      setIsChecked(false);
      toast({
        title: "An error occurred",
        description: "We were unable to send your message. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <Flex
        direction={{ base: "column", md: "row" }}
        align="start"
        gap={4}
        mb={4}
      >
        <FormControl id="name" isRequired>
          <FormLabel>{t("contactForm.name")}</FormLabel>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            // _autofill={{ textFillColor: "red", bg: "none", opacity: "transparent" }}
          />
        </FormControl>
        <FormControl id="surname">
          <FormLabel>{t("contactForm.surname")}</FormLabel>
          <Input
            type="text"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </FormControl>
      </Flex>

      <Flex direction={{ base: "column", md: "row" }} align="start" gap={4}>
        <FormControl id="email" isRequired>
          <FormLabel>{t("contactForm.email")}</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl id="phone">
          <FormLabel>{t("contactForm.phone")}</FormLabel>
          <Input
            type="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </FormControl>
      </Flex>

      <FormControl id="company" mt={4}>
        <FormLabel>{t("contactForm.company")}</FormLabel>
        <Input
          type="company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
      </FormControl>

      <FormControl id="message" isRequired mt={4}>
        <FormLabel>{t("contactForm.message")}</FormLabel>
        <Textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          resize="vertical"
          borderColor="lightGray"
        />
      </FormControl>

      <Flex>
        <HStack mt={4}>
          <Checkbox isChecked={isChecked} onChange={handleCheckboxChange} />
          <Text fontSize={"sm"}>
            {t("contactForm.policy")}
            <Link
              as={RouterLink}
              to={`/${language}/${t("routes.policies")}`}
              target="_blank"
              rel="noopener noreferrer"
              textDecor={"underline"}
            >
              {t("contactForm.policyLink")}
            </Link>
          </Text>
        </HStack>
      </Flex>
      <Flex>
        <CustomButton type="submit" mt={4} isDisabled={!isChecked}>
          {t("contactForm.button")}
        </CustomButton>
      </Flex>
    </Box>
  );
}
