// metadata of media is written in such manner lt-kazkoks teksts; en-some text this function splits string and creates string for specific locale

export function splitMetaDataByLang(input, preferredLanguage) {
  if (!input || typeof input !== "string") {
    return "";
  }

  const entries = input.split(";").map((entry) => entry.trim());
  const resultMap = {};

  entries.forEach((entry) => {
    const parts = entry.split("-").map((item) => item.trim());
    if (parts.length === 2) {
      // Only consider valid entries with exactly two parts
      const [lang, text] = parts;
      resultMap[lang] = text;
    }
  });

  // Return the text for the preferred language if it exists,
  // otherwise, return the text for the first language provided,
  // or an empty string if no valid entry was found.
  return resultMap[preferredLanguage] || Object.values(resultMap)[0] || "";
}

export function scrollToTop () {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};