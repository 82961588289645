import React from "react";
import { CustomButton } from "../customButton";
import { FaArrowUp } from "react-icons/fa";

export function ScrollToTopButton({ props, size, border }) {
  return (
    <CustomButton
      {...props}
      border={border}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <FaArrowUp size={size} />
    </CustomButton>
  );
}
