import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoadError } from "../../hooks/useLoadError";
import NavTabs from "../navTabs";
import SubservicesSection from "./subservicesSection";
import SectionTitle from "../sections/sectionTitle";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { t } from "i18next";

// Query services
//Get services and filter by id
// NavTabs - selecting service by id
// Subservices - show
// Subservice

const GET_SERVICESNAMES = gql`
  query GetServices($locale: I18NLocaleCode) {
    services(locale: $locale) {
      data {
        id
        attributes {
          title
          order
        }
      }
    }
  }
`;

export default function ServicesList({ scrollServiceId }) {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { i18n } = useTranslation();
  // Query actions
  const { loading, error, data } = useQuery(GET_SERVICESNAMES, {
    variables: { locale: i18n.language },
  });

  let initialTabIndex;
  if (isNaN(scrollServiceId)) {
    initialTabIndex = 0;
  } else {
    initialTabIndex = scrollServiceId;
  }

  const [selectedTabIndex, setSelectedTabIndex] = useState(initialTabIndex);
  const loadError = useLoadError(loading, error);
  if (loadError) return loadError;

  const services = data.services.data;

  const sortedServices = [...services].sort((a, b) => {
    return a.attributes.order - b.attributes.order;
  });

  const handleTabChange = (index) => {
    setSelectedTabIndex(index);
  };

  const selectedServiceId =
    sortedServices.length > selectedTabIndex
      ? sortedServices[selectedTabIndex].id
      : null;

  return (
    <Box
      position="relative"
      _before={
        !isMobile
          ? {
              content: '""',
              display: "block",
              position: "absolute",
              top: "70%",
              left: "20%",
              transform: "translate(-50%, -50%)",
              h: "100vh",
              w: "100vh",
              borderRadius: "50%",
              bgGradient: "radial(circle, mainAc 0%, transparent 70% )",
              zIndex: "-100",
              opacity: "0.15",
            }
          : undefined
      }
    >
      <SectionTitle title={t("sectionTitle.servicesServices")} />
      <NavTabs
        index={selectedTabIndex}
        onChange={handleTabChange}
        object={sortedServices}
      />
      <SubservicesSection selectedServiceId={selectedServiceId} />
    </Box>
  );
}
