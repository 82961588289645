import React from "react";
import {
  Box,
  Container,
  Flex,
  HStack,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { containerW } from "../../utils/globalVars";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import RenderServiceContentByType from "./virtualTour/renderServiceContentByType";
import ReactMarkdown from "react-markdown";
import ContentLoader from "../animations/contentLoader";

const GET_SUBSERVICESBYID = gql`
  query GetSubservicesById($serviceId: ID, $locale: I18NLocaleCode) {
    subservices(
      filters: { service: { id: { eq: $serviceId } } }
      locale: $locale
    ) {
      data {
        id
        attributes {
          order
          title
          description
          bulletsTitle
          descriptionPoints
          service {
            data {
              id
            }
          }
          content_type {
            ... on ComponentContentTypesSingleVideo {
              video {
                data {
                  attributes {
                    url
                  }
                }
              }
              thumbnail {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            ... on ComponentContentTypesImageVideo {
              media {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
            }
            ... on ComponentContentTypesVirtualTour {
              url
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function SubservicesSection({ selectedServiceId }) {
  const { i18n, t } = useTranslation();
  const { loading, error, data } = useQuery(GET_SUBSERVICESBYID, {
    variables: { locale: i18n.language, serviceId: selectedServiceId },
  });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  if (loading)
    return (
      <Container maxW={containerW} pt="50px" pb="100px">
        <ContentLoader h="200px" loading={loading} />
      </Container>
    );
  if (error) return <p>Error: {error.message}</p>;

  const subservices = data.subservices.data;

  const sortedSubservices = [...subservices].sort((a, b) => {
    return a.attributes.order - b.attributes.order;
  });

  return (
    <>
      <Container
        maxW={containerW}
        paddingBottom={{ base: "60px", lg: "120px" }}
        paddingTop={{ base: "25px", lg: "50px" }}
      >
        <VStack gap={{ base: "50px", md: "75px", lg: "100px" }}>
          {sortedSubservices.map((subservice, index) => (
            <Flex
              key={index}
              align={{ base: "unset", md: "flex-start" }}
              // align="flex-start"
              direction={{ base: "column", lg: "row" }}
              h={{ base: "100%", lg: "500px" }}
              gap="0"
              w={"100%"}
              boxShadow={"shadowRenderServiceContentByType"}
            >
              <VStack
                flex={1.25}
                alignItems="start"
                // order={{ base: "unset", lg: index % 2 === 1 ? 2 : 1 }}
                mr={{ base: 0, lg: index % 2 === 0 ? 0 : 0 }}
                ml={{ base: 0, lg: index % 2 === 1 ? 0 : 0 }}
                marginBottom={{ base: 0, lg: "unset" }}
                h={"inherit"}
                w={"100%"}

              >
                <VStack
                  h={"100%"}
                  w={"100%"}
                  alignItems="left"
                  bg={"portfolioBg"}
                  gap={2}
                  p={8}
                >
                  {/* Row 1 */}
                  <HStack alignItems="center" justifyContent="left" spacing={4}
                  >
                    {/* <Circle
                      size="15px"
                      bgGradient={
                        "radial(circle, transparent 20%, mainAc 60%, transparent 90% )"
                      }
                    /> */}
                    <Text fontSize={"2xl"} fontWeight="bold" align={"left"}>
                      {subservice.attributes.title}
                    </Text>
                  </HStack>
                  <Text fontSize={{ base: "sm", md: "md" }} align={"justify"}>
                    {subservice.attributes.description}
                  </Text>
                  {subservice.attributes.bulletsTitle && (
                    <>
                      <Text fontSize="lg" align="left" fontWeight="bold">
                        {t("subservicesDescriptionSplitText")}
                      </Text>
                    </>
                  )}
                  <Box
                    fontSize={{ base: "sm", md: "md" }}
                    align={"justify"}
                    pl={4}
                  >
                    <ReactMarkdown>
                      {subservice.attributes.descriptionPoints}
                    </ReactMarkdown>
                  </Box>
                </VStack>
              </VStack>
              <Box
                h={"100%"}
                flex={2}
                order={{ base: "unset", lg: index % 2 === 1 ? 1 : 2 }}
              >
                <Box
                  h={"100%"}
                  // bgGradient="linear(30deg, bgNav 0% ,bgNav 50%, bgNav 100%)"
                >
                  <RenderServiceContentByType
                    object={subservice.attributes}
                    noshadow
                  />
                </Box>
              </Box>
            </Flex>
          ))}
        </VStack>
      </Container>
    </>
  );
}
