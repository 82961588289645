import React from "react";

import { Box, Image, Text, VStack } from "@chakra-ui/react";
import SectionTitle from "../sections/sectionTitle";
import { containerW } from "../../utils/globalVars";
import { useTranslation } from "react-i18next";
import { gql, useQuery } from "@apollo/client";
import LoadingSpinner from "../loadingSpinner";
import { splitMetaDataByLang } from "../../utils/globalFunctions";

const GET_SERVICESPAGEABOUT = gql`
  query GetServicesPageAbout($locale: I18NLocaleCode) {
    servicesPage(locale: $locale) {
      data {
        attributes {
          title
          description1
          description2
          description3
          image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

export default function ServicesLanding() {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const { loading, error, data } = useQuery(GET_SERVICESPAGEABOUT, {
    variables: { locale: i18n.language },
  });

  // locale settings

  if (loading) return <LoadingSpinner loading={loading} center size="xl" />;
  if (error) return <p>Error: {error.message}</p>;

  const servicesPageAbout = data.servicesPage.data;

  return (
    <Box position="relative" overflow="hidden" top="-70px" mb={"-70px"}>
      <Image
        //   position={"absolute"}
        //   top={"50%"}
        //   left={"50%"}
        //   w={"100%"}

        w={"100%"}
        h={"700px"}
        objectFit="cover"
        src={servicesPageAbout.attributes.image.data.attributes.url}
        alt={splitMetaDataByLang(
          servicesPageAbout.attributes.image.data.attributes.alternativeText,
          language
        )}
        zIndex="-100"
      ></Image>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        zIndex="1"
        transform="translateX(-50%) translateY(-50%)"
        maxW={containerW}
        w={"100%"}
      >
        <SectionTitle title={servicesPageAbout.attributes.title} />
        <VStack w={"80%"} m="0 auto" spacing={8}>
          <Text fontSize={{ base: "sm", lg: "lg" }}>
            {servicesPageAbout.attributes.description1}
          </Text>
          <Text fontSize={{ base: "sm", lg: "lg" }}>
            {servicesPageAbout.attributes.description2}
          </Text>
          <Text fontSize={{ base: "sm", lg: "lg" }}>
            {servicesPageAbout.attributes.description3}
          </Text>
        </VStack>
      </Box>
    </Box>
  );
}
