

export const mainMenuLinks = [
    {
      route: "",
      label: "menu.homepage",
    },
    {
      route: "routes.portfolio",
      label: "menu.portfolio",
    },
    {
      route: "routes.services",
      label: "menu.services",
    },
    {
      route: "routes.contact",
      label: "menu.contacts",
    },
  ];