import React from "react";
import { useQuery, gql } from "@apollo/client";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
} from "@chakra-ui/react";
import ContentTypeImagesGallery from "./contentType/contentTypeImagesGallery";
import ContentTypeSingleVideo from "./contentType/contentTypeSingleVideo";
import ContentTypeVirtualTour from "./contentType/contentTypeVirtualTour";

const GET_PORTFOLIO_IMAGES = gql`
  query GetPortfolioImages($portfolioId: ID!) {
    portfolioItem(id: $portfolioId) {
      data {
        id
        attributes {
          main_image {
            data {
              id
              attributes {
                alternativeText
                url
              }
            }
          }
          content_type {
            __typename
            ... on ComponentContentTypesPortfolioImagesGallery {
              images {
                data {
                  id
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
            ... on ComponentContentTypesPortfolioSingleVideo {
              video {
                data {
                  id
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              
            }
            ... on ComponentContentTypesPortfolioVirtualTour {
              url
            }
          }
        }
      }
    }
  }
`;

export default function PortfolioModal({ portfolioId, isOpen, onClose }) {
  const { loading, error, data } = useQuery(GET_PORTFOLIO_IMAGES, {
    variables: { portfolioId },
    skip: !isOpen,
  });

  // Fetch checks

  if (loading) return <Spinner size="xl" />;
  if (error) return <div>Error! {error.message}</div>;
  if (loading || error || !data) return null;

  // Shorten type

  const contentTypes = data.portfolioItem.data.attributes.content_type;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="rgba(0, 0, 0, 0.75)" />
      <ModalContent maxWidth="1600px" bg={"none"} m={0} position={"relative"}>
        {contentTypes.map((contentType) => {
          switch (contentType.__typename) {
            case "ComponentContentTypesPortfolioVirtualTour":
              return (
                <ModalBody key={contentType.__typename}>
                  <ContentTypeVirtualTour
                    portfolioItem={data.portfolioItem.data.attributes}
                    onClose={onClose}
                  />
                </ModalBody>
              );
            case "ComponentContentTypesPortfolioImagesGallery":
              return (
                <ModalBody key={contentType.__typename}>
                  <ContentTypeImagesGallery
                    portfolioItem={
                      data.portfolioItem.data.attributes.content_type[0]
                    }
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                </ModalBody>
              );
            case "ComponentContentTypesPortfolioSingleVideo":
              return (
                <ModalBody key={contentType.__typename}>
                  <ContentTypeSingleVideo
                    portfolioItem={data.portfolioItem.data.attributes}

                    onClose={onClose}
                  />
                </ModalBody>
              );
            default:
              return null;
          }
        })}
      </ModalContent>
    </Modal>
  );
}
