import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, flexbox } from "@chakra-ui/react";

export default function HeaderLink({
  isCurrentLocation,
  content,
  to,
  onClick,
  isMobile,
}) {
  return isMobile ? (
    <Box
      display={"flex"}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
      h={"100%"}
      w={"100%"}
    >
      <Link
        w={"100%"}
        onClick={onClick}
        fontSize="md"
        as={RouterLink}
        to={to}
        color={isCurrentLocation ? "mainAc" : "mainWhite"}
        borderLeft={"4px solid"}
        borderLeftColor={isCurrentLocation ? "mainAc" : "transparent"}
        borderRight={"4px solid"}
        borderRightColor={"transparent"}
        h="45px"
        pl={"20px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
        textAlign="left"
        _hover={{
          color: "mainAc",
          borderLeftColor: "mainAc",
        }}
      >
        {content}
      </Link>
    </Box>
  ) : (
    <Box h={"100%"} position="relative">
      <Link
        onClick={onClick}
        fontSize="md"
        as={RouterLink}
        to={to}
        color={isCurrentLocation ? "mainAc" : "mainWhite"}
        borderBottom={"4px solid"}
        borderBottomColor={isCurrentLocation ? "mainAc" : "transparent"}
        borderTop={"4px solid"}
        borderTopColor={"transparent"}
        h="100%"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        _hover={{
          color: "mainAc",
          borderBottomColor: "mainAc",
        }}
      >
        {content}
      </Link>
    </Box>
  );
}
