import React from "react";
import { Box, Container, Text, Flex, HStack } from "@chakra-ui/react";
import { useQuery, gql } from "@apollo/client";
import { containerW } from "../../utils/globalVars";
import SectionTitle from "../sections/sectionTitle";
import { useTranslation } from "react-i18next";
import { useLoadError } from "../../hooks/useLoadError";
import { motion } from "framer-motion";
import { t } from "i18next";

const MotionBox = motion(Box); // Create a motion component

const GET_SERVICESCARDS = gql`
  query GetServicesCards($locale: I18NLocaleCode) {
    servicesCards(locale: $locale) {
      data {
        id
        attributes {
          order
          title
          description
        }
      }
    }
  }
`;

export default function ServicesCards() {
  // Query actions

  const { i18n } = useTranslation();
  const { loading, error, data } = useQuery(GET_SERVICESCARDS, {
    variables: { locale: i18n.language },
  });

  const loadError = useLoadError(loading, error);
  if (loadError) return loadError;

  // Animation code

  const fadeInUp = {
    hidden: { opacity: 0, y: 60 },
    show: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
  };

  // Services cards sort function

  const servicesCards = data.servicesCards.data;
  const sortedServicesCards = [...servicesCards].sort(
    (a, b) => a.attributes.order - b.attributes.order
  );

  return (
    <Box bgGradient="linear(to top, transparent 0% ,mainAcBgServiceCards 50%, transparent 100%)">
      <Container maxW={containerW}>
        <Box pb={{ base: "50px", md: "120px" }} pt={"30px"}>
          <SectionTitle title={t("sectionTitle.servicesProcess")} />
          <Flex direction="row" wrap="wrap" justify="center" gap={6}>
            {sortedServicesCards.map((card, index) => (
              <MotionBox
                boxShadow={{ base: "customBase", md: "custom1" }}
                key={card.id}
                bg={"portfolioBg"}
                overflow="hidden"
                py={{ base: "30px", md: "40px" }}
                px={6}
                w={{ base: "100%", md: "250px" }}
                h={{ base: "100%", md: "250px" }}
                position={"relative"}
                initial="hidden"
                animate="show"
                variants={fadeInUp}
                // responsive width
              >
                <Text
                  position={"absolute"}
                  fontSize={"350px"}
                  color={"mainAcSecondary"}
                  opacity={"0.15"}
                  top="45%"
                  left="75%"
                  transform="translate(-50%, -50%)"
                >
                  {index + 1}
                </Text>
                <Flex direction={"column"}>
                  <Box
                    display="flex"
                    alignItems="center"
                    h={{ base: "30px", md: "50px" }}
                    pb={5}
                  >
                    <Text
                      fontWeight="700"
                      fontSize="lg"
                      mb={2}
                      align={"left"}
                      verticalAlign={"center"}
                    >
                      {card.attributes.title}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize={{ base: "sm", lg: "md" }}align={"left"}>
                      {card.attributes.description}
                    </Text>
                  </Box>
                </Flex>
              </MotionBox>
            ))}
          </Flex>
        </Box>
      </Container>
    </Box>
  );
}
