import { Flex, Link, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";

export default function CompanyInfo({ iconSize, fontSize, spacing }) {
  const { t } = useTranslation();

  const Info = ({ icon, href, text}) => (
    <>
      <Flex gap={4} align="center">
        {icon}
        <Link href={href} fontSize={fontSize} color="mainWhite">
          {text}
        </Link>
      </Flex>
    </>
  );

  return (
    <VStack alignItems="start" spacing={spacing}>
      <Info
        text={t("brand.location")}
        icon={<FaMapMarkerAlt size={ iconSize } />}
        href="https://maps.app.goo.gl/F4CrfnD9ktcZVLeG7"
      />
      <Info
        text={`info@3dvizuals.com`}
        icon={<FaEnvelope size={ iconSize } />}
        href="mailto:info@3dvizuals.com"
      />
      <Info
        text={`+370 636 90528`}
        icon={<FaPhoneAlt size={iconSize } />}
        href="tel:+37063690528"
      />
    </VStack>
  );
}
