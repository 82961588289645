import { Box, Image, Spinner, useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import RightArrowIcon from "../../icons/rightArrowIcon";
import LeftArrowIcon from "../../icons/leftArrowIcon";
import CrossIcon from "../../icons/crossIcon";
import { useTranslation } from "react-i18next";
import { splitMetaDataByLang } from "../../../utils/globalFunctions";

export default function ContentTypeImagesGallery({
  portfolioItem,
  isOpen,
  onClose,
}) {
  // Element styling
  // Sets responsive sizes of navigation icons\
  const iconSize = useBreakpointValue({ base: "sm", md: "md" });

  // Hooks

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isImageLoading, setIsImageLoading] = useState(true);

  useEffect(() => {
    if (!isOpen) {
      setCurrentImageIndex(0);
      setIsImageLoading(true);
    }
  }, [isOpen]);

  // Language for image alts
  const { i18n } = useTranslation();
  const language = i18n.language;

  // Function - changes image to after action forward or backward index

  const changeImage = (direction) => {
    if (!portfolioItem) return;

    let newIndex = currentImageIndex + direction;
    const imagesCount = portfolioItem.images.data.length;

    if (newIndex >= imagesCount) {
      newIndex = 0;
    }
    if (newIndex < 0) {
      newIndex = imagesCount - 1;
    }

    setCurrentImageIndex(newIndex);
  };

  // Function - changes image on location (midpoint) where it is pressed

  const handleImageClick = (e) => {
    // Get the width of the image and its left offset relative to the viewport
    const { width, left } = e.target.getBoundingClientRect();

    // Calculate the midpoint of the image
    const midpoint = left + width / 2;

    // Determine which side was clicked and change the image accordingly
    if (e.clientX < midpoint) {
      // Left side was clicked
      setIsImageLoading(true);
      changeImage(-1);
    } else {
      // Right side was clicked
      setIsImageLoading(true);
      changeImage(1);
    }
  };

  //  Sets starting image

  const currentImage = portfolioItem.images.data[currentImageIndex];
  const alt = currentImage.attributes.alternativeText;

  return (
    <>
      {isImageLoading && (
        <Spinner
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          size="xl"
        />
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={onClose}
      >
        <Box
          style={{ visibility: isImageLoading ? "hidden" : "visible" }}
          display="inline-block"
          zIndex={"100"}
          onClick={(e) => e.stopPropagation()}
        >
          <Image
            src={`${currentImage.attributes.url}`}
            alt={splitMetaDataByLang(alt, language)}
            maxH={"95vh"}
            onLoad={(e) => {
              setIsImageLoading(false);
              e.target.parentNode.style.position = "relative";
              console.log("loaded");
            }}
            onError={() => setIsImageLoading(false)}
            onClick={handleImageClick}
          />
          {!isImageLoading && (
            <>
              <Box
                position={"absolute"}
                top="50%"
                left="10px"
                transform="translateY(-50%)"
              >
                <LeftArrowIcon
                  size={iconSize}
                  handleChange={() => {
                    setIsImageLoading(true);
                    changeImage(-1);
                  }}
                />
              </Box>
              <Box
                position={"absolute"}
                top="50%"
                right="10px"
                transform="translateY(-50%)"
              >
                <RightArrowIcon
                  size={iconSize}
                  handleChange={() => {
                    setIsImageLoading(true);
                    changeImage(1);
                  }}
                />
              </Box>
              <Box
                position={"absolute"}
                top="30px"
                right="10px"
                transform="translateY(-50%)"
              >
                <CrossIcon
                  size={iconSize}
                  handleChange={onClose}
                  style={{
                    visibility: isImageLoading ? "hidden" : "visible",
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
