import React from "react";
import { HStack, Link } from "@chakra-ui/react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { gql, useQuery } from "@apollo/client";

const GET_SOCIALLINKS = gql`
  query GetSocials {
    socials {
      data {
        id
        attributes {
          provider
          link
        }
      }
    }
  }
`;

export default function Social({ size }) {
  // Query logic
  const { loading, error, data } = useQuery(GET_SOCIALLINKS);
  if (loading) return;
  if (error) return console.log(error);

  // Function to set social icons
  function getIconComponent(provider, size) {
    switch (provider) {
      case "facebook":
        return <FaFacebook size={size} />;
      case "twitter":
        return <FaTwitter size={size} />;
      case "instagram":
        return <FaInstagram size={size} />;
      case "linkedIn":
        return <FaLinkedinIn size={size} />;
      default:
        console.error(`Icon for provider "${provider}" not found.`);
        return; // Return a default icon or null if provider is not found
    }
  }

  // Data
  const social = data.socials.data;

  return (
    <HStack spacing={4}>
      {social.map((social) => (
        <Link key={social.id} href={social.attributes.link} isExternal>
          {getIconComponent(social.attributes.provider, size)}
        </Link>
      ))}
    </HStack>
  );
}
