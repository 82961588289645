import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  VStack,
  Text,
  Link,
  Image,
  useBreakpointValue,
  HStack,
  Grid,
  Spacer,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { containerW } from "../../utils/globalVars";
import Social from "../elements/social";
import CompanyInfo from "../elements/companyInfo";
import { logo, logoTextWhite } from "../../utils/media";
import { ScrollToTopButton } from "../elements/scrollToTop";
import FooterMainMenu from "./footerMainMenu";
import SpacerLine from "../elements/spacerLine";
import { scrollToTop } from "../../utils/globalFunctions";

export default function Footer() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const isCurrentLocation = (path) => {
    const targetPath = `/${language}${path}`;
    return targetPath === currentPath || targetPath === `${currentPath}/`;
  };

  return (
    <Box bg={"bgNav"} py={6}>
      <Container maxW={containerW} position={"relative"}>
        {isMobile ? (
          <VStack gap={8} w={"100%"}>
            <ScrollToTopButton border="none" size={"md"} />
            <Box pt={""}>
              <Link as={RouterLink} to={`/${language}`}>
                <Image h={"70px"} src={logo} />
              </Link>
            </Box>

            <Social size={"24px"} />
            <FooterMainMenu
              isCurrentLocation={isCurrentLocation}
              isMobile={isMobile}
            />
            <CompanyInfo fontSize={"sm"} iconSize={"16px"} />
            <SpacerLine color={"lightGray"} />
            <HStack gap={5}>
              <Link
                fontSize={"sm"}
                as={RouterLink}
                to={`/${language}/${t("routes.policies")}`}
                _hover={{
                  textDecoration: "none",
                  _focus: { boxShadow: "none" },
                }}
                onClick={scrollToTop}

              >
                {t("menu.privacy-policy")}
              </Link>
              <Text fontSize="sm">
                &copy; {new Date().getFullYear()} {t("rights")}
              </Text>
            </HStack>
          </VStack>
        ) : (
          <>
            <Grid gap={3}>
              <HStack alignItems={"flex-start"} mb={5}>
                <VStack alignItems="start" spacing={2} w={"275px"}>
                  <Box pb={4}>
                    <Link as={RouterLink} to={`/${language}`}>
                      <Image h={"35px"} src={logoTextWhite} />
                    </Link>
                  </Box>
                  <Social />
                </VStack>
                <FooterMainMenu
                  isCurrentLocation={isCurrentLocation}
                  isMobile={isMobile}
                />

                <CompanyInfo fontSize={"sm"} iconSize={"15px"} spacing={2} />
                <Spacer />
                <ScrollToTopButton border="none" size={"md"} />
              </HStack>
            </Grid>
          </>
        )}
      </Container>

      {!isMobile && (
        <>
          <Box h={"0.5px"} bg={"mainAc"} w={"100%"} opacity={"0.4"} />

          <Container maxW={containerW} position={"relative"} mt={4}>
            <HStack justifyContent={"space-between"} w={"100%"}>
              <Link
                fontSize={"sm"}
                as={RouterLink}
                to={`/${language}/${t("routes.policies")}`}
                onClick={scrollToTop}
              >
                {t("menu.privacy-policy")}
              </Link>
              <Text fontSize="sm">
                &copy; {new Date().getFullYear()} {t("rights")}
              </Text>
            </HStack>
          </Container>
        </>
      )}
    </Box>
  );
}
