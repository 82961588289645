import React from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, Tabs, Flex } from "@chakra-ui/react";

export default function NavTabs({
  index,
  onChange,
  object,
  allTab,
  manualLang,
  responsive
}) {
  const { t } = useTranslation();

  //   const sortedObjects = [...object].sort((a, b) => {
  //     return a.attributes.order - b.attributes.order;
  //   });

  return (
    <Tabs
      index={index}
      onChange={(index) => onChange(index)}
      variant="customStyle"
      display={responsive ? { base: "none", md: "block" } : { base: "block" }}

      className="gradient-border"

    >
      <TabList justifyContent="center">
        <Flex>
          {object.map((object) =>
            manualLang ? (
              <Tab key={object.id}>
                {t(`navTabs.${object.attributes.title}`)}
              </Tab>
            ) : (
              <Tab key={object.id}>{object.attributes.title}</Tab>
            )
          )}
          {allTab && <Tab key="all">{t("navTabs.all")}</Tab>}
        </Flex>
      </TabList>
    </Tabs>
  );
}
