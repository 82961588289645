import { Center, Spinner } from "@chakra-ui/react";
import React from "react";

export default function LoadingSpinner({ loading, center, size="lg" }) {
  if (loading)
    return (
      <Center h={center ? "100vh" : "100%"}>
        <Spinner size={size} color="mainAc" />
      </Center>
    );
}
