import React from "react";
import { mainMenuLinks } from "../../utils/menus";
import { Box, VStack } from "@chakra-ui/react";
import FooterLink from "./footerLink";
import { useTranslation } from "react-i18next";
import SpacerLine from "../elements/spacerLine";
import { scrollToTop } from "../../utils/globalFunctions";

export default function FooterMainMenu({ isMobile, isCurrentLocation }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  return (
    <VStack
      alignItems={{ base: "center", md: "flex-start" }}
      spacing={{base: "0", md: "2"}}
      w={{ base: "100%", md: 150 }}
    >
      {mainMenuLinks.map((link, index) => {
        const mobileStyles = {
          p: "0",
          m: "0",
        };

        const styles = {
        };

        return (
          <Box
            w={"100%"}
            m={"0"}
            {...(isMobile ? mobileStyles : styles)}
            key={index}
          >
            {isMobile && index === 0 && <SpacerLine color={"lightGray"} />}
            <FooterLink
              isCurrentLocation={isCurrentLocation(`/${t(link.route)}`)}
              content={isMobile ? t(link.label).toUpperCase() : t(link.label)}
              to={`/${language}/${t(link.route)}`}
              onClick={scrollToTop}
              isMobile={isMobile}
            />
            {isMobile && <SpacerLine color={"lightGray"} />}
          </Box>
        );
      })}
    </VStack>
  );
}
