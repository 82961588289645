import React from "react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import Icon from "./icon";

export default function LeftArrowIcon({ handleChange, size }) {
  return (
    <Icon
      onClick={handleChange}
      justify="start"
      icon={ChevronLeftIcon}
      size={size}
    ></Icon>
  );
}
