import React from "react";
import { Box, Container, HStack, Text } from "@chakra-ui/react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/loadingSpinner";
import SectionTitle from "../components/sections/sectionTitle";
import { containerW, domainName } from "../utils/globalVars";
import ReactMarkdown from "react-markdown";
import { useLocation } from "react-router-dom";
import PageSEO from "../components/elements/pageSEO";

const GET_PRIVACYPOLICYPAGE = gql`
  query GetPrivacyPolicyPage(
    $locale: I18NLocaleCode
    $pagination: PaginationArg
  ) {
    privacyPolicyPage(locale: $locale) {
      data {
        attributes {
          title
          description
          section(pagination: $pagination) {
            id
            title
            item {
              id
              text
              subItem
            }
          }
        }
      }
    }
  }
`;

export default function Policies() {
  const { i18n } = useTranslation();

  const { loading, error, data } = useQuery(GET_PRIVACYPOLICYPAGE, {
    variables: {
      locale: i18n.language,
      pagination: {
        start: 0,
        limit: 20,
      },
    },
  });

  if (loading) return <LoadingSpinner loading={loading} center size="xl" />;
  if (error) return <p>Error: {error.message}</p>;

  const policiesContent = data.privacyPolicyPage.data;

  return (
    <Container maxW={containerW} pb={10}>

      {/* Page meta information */}
      <PageSEO page={"policies"}/>
      {/* Page meta information */}

      <SectionTitle title={policiesContent.attributes.title} />
      <Text align={"left"} pb={8}>
        <ReactMarkdown>{policiesContent.attributes.description}</ReactMarkdown>
      </Text>

      {policiesContent.attributes.section.map((section, sectionIndex) => (
        <Box key={section.id} pt={2} pb={2} w={"100%"}>
          <HStack pb={4}>
            <Text
              fontSize={"2xl"}
              fontWeight="bold"
              align={"left"}
              textColor={"mainAc"}
            >
              {sectionIndex + 1}
            </Text>
            <Text
              fontSize={"2xl"}
              fontWeight="bold"
              align={"left"}
              pl={4}
              textColor={"mainAc"}
            >
              {section.title}
            </Text>
          </HStack>
          {section.item.map((item, itemIndex) => (
            <Box key={item.id} pl={4}>
              <HStack align={"start"} pt={1} pb={1}>
                <Box textColor={"mainAc"}>{`${sectionIndex + 1}.${
                  itemIndex + 1
                }`}</Box>
                <Box>
                  <Text align={"start"} pl={2}>
                    {item.text}
                  </Text>
                  {item.subItem &&
                    item.subItem.statements.map((subItem, subItemIndex) => {
                      const letters = [
                        "a",
                        "b",
                        "c",
                        "d",
                        "e",
                        "f",
                        "g",
                        "h",
                        "i",
                        "j",
                      ]; // you can extend this as required
                      return (
                        <HStack
                          key={subItemIndex}
                          align={"start"}
                          pt={1}
                          pb={1}
                        >
                          <Box textColor={"mainAc"}>
                            {letters[subItemIndex] || "n/a"}{" "}
                          </Box>
                          <Box>
                            <Text align={"start"} pl={2}>
                              {subItem}
                            </Text>
                          </Box>
                        </HStack>
                      );
                    })}
                </Box>
              </HStack>
            </Box>
          ))}
        </Box>
      ))}
    </Container>
  );
}
