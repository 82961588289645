import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Fade,
  Grid,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import LeftArrowIcon from "../icons/leftArrowIcon";
import { CustomButton } from "../customButton";
import RightArrowIcon from "../icons/rightArrowIcon";
import LoadingSpinner from "../loadingSpinner";

const SLICE_QUERY = gql`
  query GetSlides($locale: I18NLocaleCode) {
    slides(locale: $locale) {
      data {
        id
        attributes {
          order
          heading
          description
          mediaType
          media {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default function SectionLanding() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { data, loading, error } = useQuery(SLICE_QUERY, {
    variables: { locale: i18n.language },
  });

  const [selectedSlideIndex, setSelectedSlide] = useState(0);
  const [sortedSlides, setSortedSlides] = useState([]);

  useEffect(() => {
    if (data && data.slides && data.slides.data) {
      const slides = data.slides.data;
      const newSortedSlides = [...slides].sort((a, b) => {
        return a.attributes.order - b.attributes.order;
      });
      setSortedSlides(newSortedSlides); // Update sortedSlides state
    }
  }, [data]);

  useEffect(() => {
    if (!sortedSlides || !sortedSlides.length) return;

    const timer = setTimeout(() => {
      handleSlideChange((selectedSlideIndex + 1) % sortedSlides.length);
    }, 9000); // 7 seconds

    // Clear the timer when the component is unmounted or if the slide is changed.
    return () => {
      clearTimeout(timer);
    };
  }, [selectedSlideIndex, sortedSlides]);

  if (loading) return <LoadingSpinner loading={loading} />;
  if (error) return <div>Error! {error.message}</div>;

  if (!sortedSlides[selectedSlideIndex]) return null;
  const selectedSlide = sortedSlides[selectedSlideIndex];

  const handleSlideChange = (index) => {
    setSelectedSlide(index);
  };

  return (
    <Box position="relative" overflow="hidden" top="-70px" mb={"-70px"}>
      <Fade
        key={sortedSlides[selectedSlideIndex].id}
        in={true}
        transition={{ enter: { duration: 3 } }}
      >
        <Grid h={"100vh"} templateColumns={"1fr 10fr 1fr"} alignItems="center">
          <LeftArrowIcon
            handleChange={() =>
              handleSlideChange(
                (selectedSlideIndex - 1 + sortedSlides.length) %
                  sortedSlides.length
              )
            }
          />

          <Box>
            {selectedSlide.attributes.mediaType === "video" ? (
              <Box>
                <video
                  autoPlay
                  muted
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    height: "100%",
                    transform: "translate(-50%, -50%)",
                    objectFit: "cover",
                    zIndex: -100,
                  }}
                  onEnded={() =>
                    handleSlideChange(
                      (selectedSlideIndex + 1) % sortedSlides.length
                    )
                  }
                >
                  <source
                    src={`${sortedSlides[selectedSlideIndex].attributes.media.data.attributes.url}`}
                    type="video/mp4"
                  />
                </video>
              </Box>
            ) : (
              <Box>
                <Image
                  position={"absolute"}
                  top={"50%"}
                  left={"50%"}
                  w={"100%"}
                  h={"100%"}
                  objectFit="cover"
                  transform={"translate(-50%, -50%)"}
                  src={`${process.env.REACT_APP_BACKEND_URL}${sortedSlides[selectedSlideIndex].attributes.media.data.attributes.url}`}
                  zIndex="-100"
                ></Image>
              </Box>
            )}

            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              bg="black"
              opacity="0.3"
              zIndex="-50"
            ></Box>
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              textAlign="center"
            >
              <Heading as="h1" size="2xl" mb={4}>
                {sortedSlides[selectedSlideIndex].attributes.heading}
              </Heading>

              <Text
                fontSize={{ base: "md", lg: "lg" }}
                mb={4}
                _hover={{
                  shadow: "md",
                  transform: "translateY(-5px)",
                  transitionDuration: "0.2s",
                  transitionTimingFunction: "ease-in-out",
                }}
              >
                {sortedSlides[selectedSlideIndex].attributes.description}
              </Text>

              <CustomButton
                bg={"mainAcSecondary"}
                mt={4}
                as={RouterLink}
                to={`/${language}/${t("routes.services")}`}
              >
                {t("buttons.readMore")}
              </CustomButton>
            </Box>
          </Box>

          <RightArrowIcon
            handleChange={() =>
              handleSlideChange((selectedSlideIndex + 1) % sortedSlides.length)
            }
          ></RightArrowIcon>
        </Grid>
      </Fade>
    </Box>
  );
}
