import { gql, useQuery } from "@apollo/client";
import i18next from "i18next";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { domainName, siteName } from "../../utils/globalVars";
import { useLocation } from "react-router-dom";
import { logoFavicon } from "../../utils/media";

const GET_PAGESEO = gql`
  query GetPageSeo($locale: I18NLocaleCode, $page: String!) {
    pagesSeos(locale: $locale, filters: { page: { eq: $page } }) {
      data {
        attributes {
          page
          title
          description
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default function PageSEO({ page }) {
  // Url location
  const location = useLocation();

  // Language setup
  const { i18n } = useTranslation();
  const availableLanguages = Object.keys(i18n.options.resources);

  const createUrlForLanguage = (lang, location) => {
    const locationRemovedLang = location.replace(/^\/[a-z]{2}\//, "/");
    const url = `${domainName}/${lang}${locationRemovedLang}`;
    return url;
  };

  const { loading, error, data } = useQuery(GET_PAGESEO, {
    variables: { locale: i18next.language, page },
  });

  if (loading) return;
  if (error) return <p>Error: {error.message}</p>;

  const dataSeo = data.pagesSeos.data[0];

  const dataTitle = dataSeo.attributes.title;
  const dataDescription = dataSeo.attributes.description;
  const dataImage = dataSeo.attributes.image.data.attributes.url;
  const locationUrl = `${domainName}${location.pathname}`;

  // console.log(availableLanguages);

  return (
    <Helmet>
      <title>{dataTitle}</title>
      <link rel="canonical" href={locationUrl} />

      {availableLanguages.map((lang) => {
        const url = createUrlForLanguage(lang, location.pathname);
        return <link rel="alternate" hrefLang={lang} href={url} key={lang} />;
      })}

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <meta name="description" content={dataDescription} />
      <meta property="og:title" content={dataTitle} />
      <meta property="og:description" content={dataDescription} />
      <meta property="og:image" content={dataImage} />
      <meta property="og:url" content={locationUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteName}></meta>
      <meta property="og:locale" content={i18n.language}></meta>
      <meta name="twitter:title" content={dataTitle} />
      <meta name="twitter:description" content={dataDescription} />
      <meta name="twitter:image" content={dataImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="icon" href={logoFavicon} type="image/x-icon" />
      <link rel="apple-touch-icon" href={dataImage} />



    </Helmet>
  );
}
