import React from "react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import Icon from "./icon";

export default function CrossIcon({ handleChange, size }) {
  return (
    <Icon
      onClick={handleChange}
      icon={SmallCloseIcon}
      size={size}

    ></Icon>
  );
}
