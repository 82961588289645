import { gql, useQuery } from "@apollo/client";
import { Container, useBreakpointValue } from "@chakra-ui/react";
import React, { useState } from "react";
import NavTabs from "../components/navTabs";
import PortfolioSection from "../components/portfolio/portfolioSection";
import { CustomButton } from "../components/customButton";
import { useTranslation } from "react-i18next";
import PageSEO from "../components/elements/pageSEO";
import { useLocation } from "react-router-dom";

const GET_PORTFOLIOS = gql`
  query GetPortfolioItems($pagination: PaginationArg!) {
    portfolioItems(pagination: $pagination, sort: "order:asc") {
      data {
        id
        attributes {
          order
          content_type {
            __typename
          }
          category {
            data {
              id
              attributes {
                title
              }
            }
          }
          main_image {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default function Portfolio() {
  const [hasMore, setHasMore] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const columnCount = useBreakpointValue({ base: 1, md: 2, xl: 3 });
  const [portfolioItems, setPortfolioItems] = useState([]);
  const customLimit = 15;
  const [start, setStart] = useState(0);

  // Localization functions
  const { t } = useTranslation();

  // Website location
  const location = useLocation();

  // Pagination custom limit
  // Alter customLimit to set how many component should be per page

  // info about pagination state stored in local storage here we give this info then component loads
  const historyLimit =
    parseInt(localStorage.getItem("portfolioPaginationLimit")) || customLimit;

  const { loading, error, fetchMore } = useQuery(GET_PORTFOLIOS, {
    variables: {
      pagination: {
        start: 0,
        limit: customLimit,
      },
    },
    onCompleted: (data) => {
      setPortfolioItems(data.portfolioItems.data);
      // Check if the number of fetched items is less than the requested limit
      if (data.portfolioItems.data.length < historyLimit) {
        setHasMore(false);
      }
    },
  });

  const loadMoreItems = () => {
    const newStart = start + customLimit;
    fetchMore({
      variables: {
        pagination: {
          start: newStart,
          limit: customLimit,
        },
      },
    }).then((fetchMoreResult) => {
      if (!fetchMoreResult.data) return;

      setStart(newStart);

      // Set new portfolio items array
      setPortfolioItems((prevItems) => [
        ...prevItems,
        ...fetchMoreResult.data.portfolioItems.data,
      ]);
      // Check if there is more elements in query
      if (fetchMoreResult.data.portfolioItems.data.length < customLimit) {
        setHasMore(false);
      }
    });
  };

  if (loading) return; // remoced spinner because after pagination occurs there might rerender happen
  // <LoadingSpinner loading={loading} center />
  if (error) return <p>Error: {error.message}</p>;

  const handleTabChange = (index, uniqueCategories) => {
    if (index < uniqueCategories.length) {
      setSelectedCategory(uniqueCategories[index].id);
    } else {
      setSelectedCategory("all");
    }
  };

  const uniqueCategories = portfolioItems.reduce((acc, portfolioItem) => {
    const category = portfolioItem.attributes.category.data;
    if (!acc.some((cat) => cat.id === category.id)) {
      acc.push(category);
    }
    return acc;
  }, []);

  const filteredPortfolios =
    selectedCategory === "all"
      ? portfolioItems
      : portfolioItems.filter(
          (portfolioItem) =>
            portfolioItem.attributes.category.data.id === selectedCategory
        );

  return (
    <>
      {/* Page meta information */}
      <PageSEO page={"portfolio"}/>

      {/* Page */}
      <Container maxW="100vw" bg={"portfolioBg"} minH={"100vh"}>
        <NavTabs
          index={
            selectedCategory === "all"
              ? uniqueCategories.length
              : uniqueCategories.findIndex((cat) => cat.id === selectedCategory)
          }
          onChange={(index) => handleTabChange(index, uniqueCategories)}
          object={uniqueCategories}
          allTab
          manualLang
        />
        <PortfolioSection
          portfolios={filteredPortfolios}
          columnCount={columnCount}
        />
        {/* automatic pagination loading after portfolioSection component end is reached on scroll*/}
        {/* <div ref={loadMoreRef} style={{ height: 20, width: "100%" }}></div> */}
        {hasMore && (
          <CustomButton mt={"20px"} mb={"50px"} onClick={loadMoreItems}>
            {t("buttons.moreWorks")}
          </CustomButton>
        )}
      </Container>
    </>
  );
}
