import { extendTheme } from "@chakra-ui/react";

export const domainName = "https://www.3dvizuals.com"
export const siteName = "3Dvizuals"

export const theme = extendTheme({
  fonts: {
    body: "mainFont",
    heading: "heading",
  },
  breakpoints: {
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
  },
  colors: {
    //basic white
    mainWhite: "#E2E8F0",
    // main accent color
    mainAc: "#0085ff",
    // main accent color secondary (hover, active)
    mainAcSecondary: "#5fb3ff55",
    // main accent color secondary (hover, active)
    mainAcBgServiceCards: "#32A0F522",
    // gray simple elements
    lightGray: "#57585b",
    // background color header, footer sections
    bgNav: "hsla(215, 25%, 20%, 1)",
    // background color main body
    bgMain: "#1A202C",
    // portfolio images background
    portfolioBg: "#0000004a",
  },
  shadows: {
    custom1: "15px 15px 20px rgba(0, 0, 0, 0.5)",
    customBase: "0px 0px 20px rgba(0, 0, 0, 0.4)",
    shadowRenderServiceContentByType: "20px 20px 30px rgba(0, 0, 0, 0.4)",
    // add as many custom shadows as you need
  },
  styles: {
    global: {
      body: {
        // default background color
        bg: "bgMain",
        color: "mainWhite",
      },
    },
  },

  components: {
    Checkbox: {
      baseStyle: {
        bg: "transparent",
        borderColor: "lightGray",

        control: {
          borderRadius: "md",
          border: "1px solid",
          borderColor: "lightGray",
        },
      },
    },
    Form: {
      baseStyle: {
        requiredIndicator: {
          color: "mainAc",
        },
      },
    },
    // FormLabel: {
    //   baseStyle: {
    //     text: {
    //       fontSize: "sm",
    //     },
    //   },
    // },

    
    Input: {
      variants: {
        backgroundFix: {
          field: {
            bg: "none",
            border: "1px solid",
            borderColor: "lightGray",

            _hover: {
              border: "1px solid",
              borderColor: "mainWhite",
            },
            _focus: {
              border: "1px solid",
              borderColor: "mainAc",
            },
            _autofill: {
              bg: "red",
              textFillColor: "red",
              styles: "background-color: red",

              // color: "red",
              // boxShadow: "0 0 0px 1000px #232323 inset",
              // transition: "background-color 5000s ease-in-out 0s",
            },
          },
        },
      },
      defaultProps: {
        variant: "backgroundFix",
      },
    },
    

    Link: {
      baseStyle: {
        _hover: {
          textDecoration: "none",
          color: "mainAc",
        },
      },
    },
    Tabs: {
      // variants: {
      //   customStyle: {

      //     color: "red",
      //     borderTop: "3px solid",
      //   },
      // },

      variants: {
        customStyle: {
          tab: {
            color: "gray",
            _selected: {
              borderTop: "3px solid",
              borderColor: "mainAc",
              color: "inherit",
              transition: "all 0.3s ease",
            },
            _hover: {
              borderTop: "3px solid",
              borderColor: "mainAc",
              transition: "all 0.5s ease",
            },
            borderTop: "3px solid",
            borderColor: "transparent",
          },
        },
      },
      baseStyle: {
        color: "red",
        borderTop: "3px solid",
      },
    },
  },
});

// Main container Width

export const containerW = "1400px";

// Theme colors
