import { Box, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import CrossIcon from "../../icons/crossIcon";
import VideoPlayer from "../../elements/videoPlayer";

export default function ContentTypeSingleVideo({ portfolioItem, onClose }) {

  // Component styling
  // Sets responsive sizes for navigation icons
  const iconSize = useBreakpointValue({ base: "sm", md: "md" });

  const thumbnail = portfolioItem.main_image.data.attributes.url;
  const videoUrl = portfolioItem.content_type[0];

  return (
    <Box h={"95vh"} position={"relative"}>
      <VideoPlayer
        videoUrl={videoUrl.video.data.attributes.url}
        thumbnail={thumbnail}
        alt={videoUrl.video.data.attributes.alternariveText}
        noBg
      ></VideoPlayer>
      <Box
        position={"absolute"}
        top="30px"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <CrossIcon
          handleChange={onClose}
          size={iconSize}
          // style={{
          //   visibility: isImageLoading ? "hidden" : "visible",
          // }}
        />
      </Box>
    </Box>
  );
}
