import React from "react";
import {
  Flex,
  Box,
  Link,
  Spacer,
  IconButton,
  Drawer,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  Image,
  theme,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import NavigationLinks from "./navigationLinks";
import { logoTextWhite } from "../../utils/media";
import { useTranslation } from "react-i18next";

export default function HeaderContent({ language, btnRef, isMobile }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { i18n } = useTranslation();


  return (
    <Flex alignItems="center" width="100%" h={"70px"}>
      <Box>
        <Link as={RouterLink} to={`/${language}`}>
          <Image h={isMobile ? "25px" : "35px"} src={logoTextWhite}
          alt={i18n.language === 'lt' ? '3Dvizuals internetinio puslapio logo' : '3Dvizuals website logo'}
          />
        </Link>
      </Box>
      <Spacer />
      {isMobile ? (
        <>
          <IconButton
          size={"sm"}
            ref={btnRef}
            aria-label="Menu"
            icon={<HamburgerIcon color={"white"} />}
            variant="outline"
            onClick={onOpen}
            _hover={{ bg: "mainAcSecondary" }}
            border={`1px solid #E2E8F0`}
          />
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent backgroundColor="bgNav">
              <DrawerCloseButton />
              <DrawerHeader />
              <DrawerBody m={0} p={0}>
                <NavigationLinks onClose={onClose} isMobile={isMobile} />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      ) : (
        <NavigationLinks onClose={onClose} isMobile={isMobile} />
      )}
    </Flex>
  );
}
