import React, { useEffect, useRef } from "react";
import ServicesCards from "../components/services/servicesCards";
import ServicesLanding from "../components/services/servicesLanding";
import ServicesList from "../components/services/servicesList";
import { useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import PageSEO from "../components/elements/pageSEO";
import { domainName } from "../utils/globalVars";

export default function Services() {
  const myRef = useRef(null);
  const location = useLocation();
  const scrollServiceString = location.hash.substring(1);
  const scrollServiceId = parseInt(scrollServiceString, 10);

  useEffect(() => {
    if (location.hash) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        const scrollOffset = myRef.current.offsetTop - 70;
        window.scrollTo({ top: scrollOffset, behavior: "smooth" });
      }, 1000);
    }
  }, [location]);

  return (
    <>
      {/* Page meta information */}
      <PageSEO page={"services"}/>

      {/* Page */}
      <ServicesLanding></ServicesLanding>
      <ServicesCards></ServicesCards>
      <Box ref={myRef}>
        <ServicesList scrollServiceId={scrollServiceId}></ServicesList>
      </Box>
    </>
  );
}
