import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { play } from "../../utils/media";
import { Box, Image } from "@chakra-ui/react";
import ImageWrapper from "./imageWrapper";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { splitMetaDataByLang } from "../../utils/globalFunctions";

export default function VideoPlayer({ videoUrl, alt, thumbnail, noBg }) {
  const [playing, setPlaying] = useState(false);
  const { t } = useTranslation();
  const language = i18next.language;
  const playerRef = useRef(null);

  const handlePause = () => {
    if (playerRef.current && !playerRef.current.isPlaying()) {
      setPlaying(false);
    }
  };

  return (
    <>
      {playing ? (
        <Box
          h={"100%"}
          bg={noBg ? "none" : "black"}
          display={"flex"}
          alignItems="center"
          justifyContent="center"
        >
          <ReactPlayer
            url={videoUrl}
            width="auto"
            height="100%"
            playing
            controls
            style={{
              visibility: playing ? "visible" : "hidden",
              cursor: "pointer",
            }}
            onPlay={() => setPlaying(true)}
            onPause={handlePause}
          />
        </Box>
      ) : (
        <ImageWrapper
          isActive
          imgUrl={thumbnail}
          cover
          loaderH={"100%"}
          alt={splitMetaDataByLang(alt, language)}
          onClick={() => setPlaying(true)}
          icon={
            <Box opacity={"0.7"}>
              <Image
                fill={"white"}
                position={"absolute"}
                top="48%"
                left="50%"
                transform="translate(-50%, -50%)"
                h={"100px"}
                src={play}
                cursor={"pointer"}
              />
            </Box>
          }
        ></ImageWrapper>
      )}
    </>
  );
}
