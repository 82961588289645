import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Footer from "./footer/footer";

const MainLayout = ({ children }) => {
  return (
    <Flex direction="column" minHeight="100vh">
      <Box flex="1">{children}</Box>
      <Footer />
    </Flex>
  );
};

export default MainLayout;
