import React, { useState } from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ImageWrapper from "../../elements/imageWrapper";
import { pano } from "../../../utils/media";
import { splitMetaDataByLang } from "../../../utils/globalFunctions";
import i18next from "i18next";

export default function VirtualTour({ url, imgUrl, overlay, alt }) {
  const [clicked, setClicked] = useState(false);
  const { t } = useTranslation();
  const language = i18next.language;


  return (
    <>
      {!overlay && (
        <iframe
          width="100%"
          height="100%"
          objectFit="cover"
          allow="xr-spatial-tracking; gyroscope; accelerometer"
          allowFullScreen
          src={url}
        />
      )}
      {clicked && (
        <iframe
          width="100%"
          height="100%"
          allow="xr-spatial-tracking; gyroscope; accelerometer"
          allowFullScreen
          objectFit="cover"
          src={url}
        />
      )}
      {!clicked && overlay && (
        <ImageWrapper
          isActive
          onClick={() => setClicked(true)}
          imgUrl={imgUrl}
          cover
          loaderH={"100%"}
          alt={splitMetaDataByLang(alt, language)}
          icon={
            <Box opacity={"0.7"}>
              <Image
                fill={"white"}
                position={"absolute"}
                top="48%"
                left="50%"
                transform="translate(-50%, -50%)"
                h={"100px"}
                src={pano}
                cursor={"pointer"}
              />
              <Text
                position={"absolute"}
                cursor={"pointer"}
                top="63%"
                left="50%"
                transform="translate(-50%, -50%)"
                textTransform={"uppercase"}
              >
                {t("virtualTour.action")}
              </Text>
            </Box>
          }
        ></ImageWrapper>
      )}
    </>
  );
}
