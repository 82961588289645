import React from "react";

const sizeVariants = {
  sm: "30px",   // Small size (e.g., 16px)
  md: "50px",   // Medium size (e.g., 20px)
  lg: "60px",   // Large size (e.g., 24px)
};


export default function Icon({ icon: Icon, onClick, justify, size = 'lg' }) {
  const boxSize = sizeVariants[size] || sizeVariants.md;


  return (
    <Icon
      onClick={onClick}
      boxSize={boxSize}
      justifySelf={justify}
      color={"mainAc"}
      bg="transparent"
      transition={"all 0.3s ease"}
      _hover={{
        color: "mainAcSecondary",
        cursor: "pointer",
      }}
    />
  );
}
