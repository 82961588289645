import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Text,
  Grid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Container,
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { CustomButton } from "../customButton";
import ServiceDetail from "./serviceDetail";
import RenderServiceContentByType from "./virtualTour/renderServiceContentByType";
import NavTabs from "../navTabs";
import RightArrowIcon from "../icons/rightArrowIcon";
import LeftArrowIcon from "../icons/leftArrowIcon";
import { containerW } from "../../utils/globalVars";
import LoadingSpinner from "../loadingSpinner";

// GraphQL query
const SERVICES_QUERY = gql`
  query GetServices($locale: I18NLocaleCode) {
    services(locale: $locale) {
      data {
        id
        attributes {
          title
          description
          order
          content_type {
            __typename
            ... on ComponentContentTypesSingleVideo {
              video {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              thumbnail {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            __typename
            ... on ComponentContentTypesImageVideo {
              media {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
            ... on ComponentContentTypesImageConmpare {
              image_1 {
                data {
                  attributes {
                    url
                  }
                }
              }
              image_2 {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            ... on ComponentContentTypesVirtualTour {
              url
              image {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function ServicesHome() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const { data, loading, error } = useQuery(SERVICES_QUERY, {
    variables: { locale: i18n.language },
  });

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  if (loading)
    return (
      <Box h={"300px"}>
        <LoadingSpinner loading={loading} />
      </Box>
    );
  if (error) return <div>Error! {error.message}</div>;

  const services = data.services.data;

  const sortedServices = [...services].sort((a, b) => {
    return a.attributes.order - b.attributes.order;
  });

  const handleTabChange = (index) => {
    setSelectedTabIndex(index);
  };

  return (
    <Box
      bgGradient={{
        base: `linear-gradient(to top, portfolioBg 0%, bgMain 60%)`,
        md: `linear-gradient(to top, portfolioBg 0%, mainAcBgServiceCards 40%, bgMain 60%)`,
      }}
    >
      <Container maxW={containerW}>
        <Box>
          {/* bigger screen navigation tabs */}

          <NavTabs
            index={selectedTabIndex}
            onChange={handleTabChange}
            object={sortedServices}
            responsive
          />

          {/* bigger screen content */}
          <Box display={{ base: "none", md: "block" }}>
            <Grid
              templateColumns={{ base: "1fr", lg: "1fr 10fr 1fr" }}
              alignItems="center"
            >
              <Box display={{ base: "none", lg: "block" }}>
                <LeftArrowIcon
                  display={"none"}
                  handleChange={() =>
                    handleTabChange(
                      (selectedTabIndex - 1 + services.length) % services.length
                    )
                  }
                />
              </Box>

              {/* Services content and description of it */}
              <ServiceDetail
                service={sortedServices[selectedTabIndex]}
                selectedTabIndex={selectedTabIndex}
              />

              <Box display={{ base: "none", lg: "block" }}>
                <RightArrowIcon
                  display={{ base: "none", lg: "block" }}
                  handleChange={() =>
                    handleTabChange((selectedTabIndex + 1) % services.length)
                  }
                ></RightArrowIcon>
              </Box>
            </Grid>
          </Box>

          {/* Smaller screen accordion */}
          <Accordion allowToggle display={{ base: "block", md: "none" }}>
            {sortedServices.map((service, index) => (
              <AccordionItem key={service.id} borderColor="lightGray">
                <h2>
                  <AccordionButton
                    onClick={() => handleTabChange(index)}
                    _expanded={{ color: "mainAc" }}
                  >
                    {/* Update selected index on click */}
                    <Box flex="1" textAlign="left">
                      {service.attributes.title}
                    </Box>
                    <AccordionIcon color={"mainAc"} />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} px={0}>
                  <Box>
                    <Text mb={8}>{service.attributes.description}</Text>
                    <Box mb={6} h={"100%"}>
                      <RenderServiceContentByType object={service.attributes} />
                    </Box>
                    <CustomButton
                      mt={4}
                      as={RouterLink}
                      to={`/${language}/${t(
                        "routes.services"
                      )}#${selectedTabIndex}`}
                    >
                      {t("buttons.readMore")}
                    </CustomButton>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
}
