import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Flex, useBreakpointValue, Container } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { containerW, theme } from "../../utils/globalVars";
import HeaderContent from "./headerContent";

export default function Header() {
  const btnRef = React.useRef();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { i18n } = useTranslation();
  const language = i18n.language;

  // Header scroll effect: works only in homepage
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* Sticky default header */}
      {location.pathname !== `/${language}` && (
        <Flex
          position="sticky"
          h={isMobile ? "50px" : "70px"}
          top={0}
          zIndex={1000}
          as="header"
          bg={theme.colors.bgNav}
          color="white"
          width="100%"
          boxShadow="md"
          alignItems="center"
        >
          <Container maxW={containerW}>
            <HeaderContent
              language={language}
              btnRef={btnRef}
              isMobile={isMobile}
            />
          </Container>
        </Flex>
      )}
      {/* Fixed header in homepage */}
      {location.pathname === `/${language}` && (
        <Flex
          h={isMobile ? "50px" : "70px"}
          position="absolute"
          top={0}
          zIndex={999}
          as="header"
          bg="rgba(0, 0, 0, 0.4)"
          color="white"
          width="100%"
          boxShadow="md"
          alignItems="center"
        >
          <Container maxW={containerW}>
            <HeaderContent
              language={language}
              btnRef={btnRef}
              isMobile={isMobile}
            />
          </Container>
        </Flex>
      )}
      {/* Sticky header in homepage */}
      {location.pathname === `/${language}` && (
        <Flex
          h={isMobile ? "50px" : "70px"}
          position="sticky"
          top={0}
          zIndex={1000}
          as="header"
          bg={theme.colors.bgNav}
          visibility={
            scrollPosition > window.innerHeight * 0.8 ? "visible" : "hidden"
          }
          opacity={scrollPosition > window.innerHeight * 0.8 ? 1 : 0}
          transition="visibility 0.3s linear, opacity 0.3s linear"
          color="white"
          width="100%"
          boxShadow="md"
          alignItems="center"
        >
          <Container maxW={containerW}>
            <HeaderContent
              language={language}
              btnRef={btnRef}
              isMobile={isMobile}
            />
          </Container>
        </Flex>
      )}
    </>
  );
}
