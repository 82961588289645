import { Box, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import PortfolioModal from "./portfolioModal";
import PortfolioItem from "./portfolioItem";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export default function PortfolioSection({ portfolios }) {
  // Hooks
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [portfolioId, setPortfolioId] = useState(null);

  // Open modal of portfolio
  const openModal = (id) => {
    setPortfolioId(id);
    onOpen();
  };

  return (
    <Box pb={10} style={{ position: "relative" }}>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 1200: 3 }}>
        <Masonry gutter="20px">
          {portfolios.map((portfolio) => (
            <PortfolioItem
              portfolio={portfolio}
              openModal={openModal}
              key={portfolio.id}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
      <PortfolioModal
        onClose={onClose}
        isOpen={isOpen}
        portfolioId={portfolioId}
      ></PortfolioModal>
    </Box>
  );
}
