import { Button } from "@chakra-ui/react";

export function CustomButton({ border = "1px solid", ...props }) {
  return (
    <Button
      type="button"
      border={border}
      backgroundColor="transparent"
      color="mainWhite"
      borderRadius="md"
      borderColor="mainAc"
      padding="0.5rem 1rem"
      _hover={{ backgroundColor: "mainAc" }}
      _active={{ backgroundColor: "mainAc" }}
      {...props}
    />
  );
}
