import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import {
  Button,
  Text,
  Slide,
  Link,
  VStack,
  HStack,
  Checkbox,
  Spacer,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { CustomButton } from "./customButton";

export default function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  // Locale parameters
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    // Gets saved consent info from local storage
    const consent = Cookies.get("cookie_consent");
    // Gets preferences from luser browser local storage
    const storedPreferences = Cookies.get("cookie_preferences");
    if (consent === "accepted") {
      try {
        // Checks if consent is stored "accepted" works as T / F
        // Gets preferences data converts from string to object and stores in preferences var
        const parsedPreferences = JSON.parse(storedPreferences);
        setPreferences(parsedPreferences);
        // console.log(parsedPreferences);
      } catch (error) {
        console.error("Failed to parse cookie preferences:", error);
      }
    } else {
      // if no consent info found sets component visibility to TRUE, component appears after delay
      setTimeout(() => {
        setIsVisible(true);
      }, 2000);
    }
  }, []);

  // Function to accept selected cookie preferences
  // Saves consent var in user browser
  // Saves preferences string with all cookies accepted consent

  const acceptAllCookies = () => {
    const allPreferences = {
      necessary: true,
      analytics: true,
      marketing: true,
    };
    Cookies.set("cookie_consent", "accepted", { expires: 30 });
    Cookies.set("cookie_preferences", JSON.stringify(allPreferences), {
      expires: 30,
    });
    setIsVisible(false);
    // Here you might want to set another cookie or perform another action
    // to track that the user accepted all cookies
  };

  // Function selected cookie preferences
  // Saves consent var in user browser
  // Saves preferences string with which cookies consent

  const acceptSelection = () => {
    Cookies.set("cookie_consent", "accepted", { expires: 30 });
    Cookies.set("cookie_preferences", JSON.stringify(preferences), {
      expires: 30,
    });

    setIsVisible(false);
    // Here you might want to set another cookie or perform another action
    // to track that the user accepted only necessary cookies
  };

  // Handle checkboxes
  const handleChange = (event) => {
    const { name, checked } = event.target;
    setPreferences({ ...preferences, [name]: checked });
  };

  return (
    <Slide in={isVisible} style={{ zIndex: 10, pointerEvents: "none" }}>
      <VStack
      borderTop={"solid 1px"}
      borderColor={"mainAcSecondary"}
      
        position="fixed"
        bottom="0"
        left="0"
        right="0"
        spacing={4}
        bg="bgNav"
        color="white"
        p="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ pointerEvents: "auto" }}
      >

        <Text fontSize="sm">
          {t("cookies.cookiesInfo")}

          <Link
            as={RouterLink}
            to={`/${language}/${t("routes.policies")}`}
            target="_blank"
            rel="noopener noreferrer"
            textDecor={"underline"}
          >
            {t("contactForm.policyLink")}
          </Link>
        </Text>
        <HStack spacing={4}>
          <Checkbox isReadOnly isChecked={preferences.necessary} isDisabled>
            {t("cookies.checkboxCookiesNecesary")}
          </Checkbox>
          <Checkbox
            name="analytics"
            isChecked={preferences.analytics}
            onChange={handleChange}
          >
            {t("cookies.checkboxCookiesAnalitics")}
          </Checkbox>
          <Checkbox
            name="marketing"
            isChecked={preferences.marketing}
            onChange={handleChange}
          >
            {t("cookies.checkboxCookiesMarketing")}
          </Checkbox>
        </HStack>

        <HStack spacing={2}>
          <CustomButton colorScheme="blue" size="sm" onClick={acceptSelection}>
            {t("cookies.buttonAgreeSelected")}
          </CustomButton>
          <CustomButton colorScheme="blue" size="sm" onClick={acceptAllCookies}>
            {t("cookies.buttonAgreeAll")}
          </CustomButton>
        </HStack>
      </VStack>
    </Slide>
  );
}
