import { gql, useQuery } from "@apollo/client";
import PortfolioSection from "../portfolio/portfolioSection";
import { useState } from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import LoadingSpinner from "../loadingSpinner";

const GET_PORTFOLIOS = gql`
  query GetPortfolios($pagination: PaginationArg!) {
    portfolioItems(pagination: $pagination, sort: "order:asc") {
      data {
        id
        attributes {
          order
          content_type {
            __typename
          }
          category {
            data {
              id
              attributes {
                title
              }
            }
          }
          main_image {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

export default function PortfolioSectionHome() {

    const [selectedCategory, setSelectedCategory] = useState("all");
    const columnCount = useBreakpointValue({ base: 1, md: 2, xl: 3 });

  const { loading, error, data } = useQuery(GET_PORTFOLIOS, {
    variables: {
      pagination: {
        start: 0,
        limit: 12,
      },
    },
  });

  if (loading) return <LoadingSpinner loading={loading} center size="xl" />;
  if (error) return <p>Error: {error.message}</p>;

// Handles which category of portfolio items to show

  // const handleTabChange = (index) => {
  //   if (index < uniqueCategories.length) {
  //     setSelectedCategory(uniqueCategories[index].id); // Set to the selected category ID
  //   } else {
  //     setSelectedCategory("all"); // Set to "all" if the "All" tab is selected
  //   }
  // };

  // Portfolio items sorted by order - value given inside query
  const portfolioItems = [...data.portfolioItems.data].sort((a, b) => {
    return a.attributes.order - b.attributes.order;
  });

  const uniqueCategories = portfolioItems.reduce((acc, portfolioItem) => {
    const category = portfolioItem.attributes.category.data;
    const categoryExists = acc.find((cat) => cat.id === category.id);

    if (!categoryExists) {
      acc.push(category);
    }

    return acc;
  }, []);

  const filteredPortfolios = (() => {
    if (selectedCategory === "all") {
      return portfolioItems;
    } else {
      return portfolioItems.filter(
        (portfolioItem) =>
          portfolioItem.attributes.category.data.id === selectedCategory
      );
    }
  })();


  return (
    <>
        {/* <NavTabs
          index={
            selectedCategory === "all"
              ? uniqueCategories.length
              : uniqueCategories.findIndex((cat) => cat.id === selectedCategory)
          }
          onChange={handleTabChange}
          object={uniqueCategories}
          allTab
          manualLang
        /> */}
      <PortfolioSection
        portfolios={filteredPortfolios}
        columnCount={columnCount}
      ></PortfolioSection>
    </>
  );
}
