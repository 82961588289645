import { useTranslation } from "react-i18next";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Box,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import ReactCountryFlag from "react-country-flag";
import { useNavigate, useLocation } from "react-router-dom";

export default function LanguageSwitcher({ onClose }) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    navigate(`/${lang}`);
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        color="mainWhite"
        variant="ghost"
        size="sm"
        m={{ base: "20px", md: "" }}
        border="1px"
        borderColor="mainAc"
        borderRadius="md"
        _hover={{ bg: "mainAcSecondary" }}
        _active={{
          bg: "mainAcSecondary",
        }}
      >
        {i18n.language.toUpperCase()}
      </MenuButton>
      <MenuList minWidth="70px" bg={"bgMain"} borderColor={"mainAc"}>
        <MenuItem
          bg={"bgMain"}
          _hover={{ bg: "mainAcSecondary" }}
          onClick={() => {
            changeLanguage("en");
            window.scrollTo({ top: 0, behavior: "smooth" });
            onClose();
          }}
        >
          <SimpleGrid columns={2}>
            <Box paddingRight={2}>
              <ReactCountryFlag
                countryCode="GB"
                svg
                style={{
                  width: "1.5em",
                }}
              />
            </Box>
            <Text color={"mainWhite"}>EN</Text>
          </SimpleGrid>
        </MenuItem>
        <MenuItem
          bg={"bgMain"}
          _hover={{ bg: "mainAcSecondary" }}
          onClick={() => {
            changeLanguage("lt");
            window.scrollTo({ top: 0, behavior: "smooth" });
            onClose();
          }}
        >
          <SimpleGrid columns={2}>
            <Box paddingRight={2}>
              <ReactCountryFlag
                countryCode="LT"
                svg
                style={{
                  width: "1.5em",
                }}
              />
            </Box>
            <Text color={"mainWhite"}>LT</Text>
          </SimpleGrid>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
