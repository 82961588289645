import { Box, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import VirtualTour from "../../services/virtualTour/virtualTour";
import CrossIcon from "../../icons/crossIcon";

export default function ContentTypeVirtualTour({ portfolioItem, onClose }) {

  // Element styling
  // Sets responsive sizes for navigation icons 
  const iconSize = useBreakpointValue({ base: "sm", md: "md" });


  const imgUrl = portfolioItem.main_image.data.attributes.url;
  const { url } = portfolioItem.content_type[0];

  return (
    <Box h={"95vh"} position={"relative"}>
      <VirtualTour
      
        url={url}
        imgUrl={`${process.env.REACT_APP_BACKEND_URL}${imgUrl}`}
      ></VirtualTour>
      <Box
        position={"absolute"}
        top="30px"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <CrossIcon
        size={iconSize}
          handleChange={onClose}
          // style={{
          //   visibility: isImageLoading ? "hidden" : "visible",
          // }}
        />
      </Box>
    </Box>
  );
}
