import React from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { containerW } from "../../utils/globalVars";
import SpacerLine from "../elements/spacerLine";

export default function SectionTitle({ seperator, title }) {
  return (
    <>
      <Flex alignItems="center" justifyContent="center" h="100px">
        <Heading fontSize="4xl">{title}</Heading>
      </Flex>
      {seperator && (
        <Box mb={"2.5em"}>
          <SpacerLine color={"mainAc"} />
        </Box>
      )}
    </>
  );
}

// <Flex
// align={{ base: "unset", lg: "flex-start" }} // Change here
// direction={{ base: "column", lg: "row" }}
// >
// <VStack
//   flex={1.2}
//   spacing={5}
//   alignItems="start"
//   order={{ base: "unset", lg: index % 2 === 1 ? 2 : 1 }}
//   mr={{ base: 0, lg: index % 2 === 0 ? 20 : 0 }}
//   ml={{ base: 0, lg: index % 2 === 1 ? 20 : 0 }}
//   marginBottom={{ base: 10, lg: "unset" }}
//   bg={""}
// >
//   <Flex
//     alignItems={{ base: "center", lg: "start" }}
//     justifyContent="center"
//     w="100%"
//   >
//     <Box borderBottom={"1px solid "} borderColor={"mainAc"}>
//       <Text fontSize={{ base: "lg", lg: "2xl" }} fontWeight="bold">
//         {subservice.attributes.title}
//       </Text>
//     </Box>
//   </Flex>
