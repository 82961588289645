import React from "react";
import { Image, Box } from "@chakra-ui/react";
import VirtualTourScript from "./virtualTour";
import ImageWrapper from "../../elements/imageWrapper";
import VideoPlayer from "../../elements/videoPlayer";

export default function RenderServiceContentByType({ object, noshadow }) {
  return object.content_type.map((content, index) => {
    return (
      <Box
        key={index}
        h={{ base: "320px", md: "100%" }}
        boxShadow={
          noshadow
            ? ""
            : { base: "320px", md: "shadowRenderServiceContentByType" }
        }
      >
        {(() => {
          switch (content.__typename) {
            case "ComponentContentTypesImageVideo":
              return (
                <ImageWrapper
                  imgUrl={`${content.media.data.attributes.url}`}
                  alt={`${process.env.REACT_APP_BACKEND_URL}${content.media.data.attributes.alternativeText}`}
                  cover
                  noBg
                  loaderH={"100%"}
                />
              );
            case "ComponentContentTypesSingleVideo":
              return (
                <VideoPlayer
                  videoUrl={`${content.video.data.attributes.url}`}
                  alt={`${process.env.REACT_APP_BACKEND_URL}${content.video.data.attributes.alternativeText}`}
                  overlay
                  thumbnail={`${content.thumbnail.data.attributes.url}`}
                />
              );
            case "ComponentContentTypesImageCompare":
              return (
                <div>
                  <Image
                    src={`${content.image_1.data.attributes.url}`}
                    alt="image 1"
                    objectFit="cover"
                    width="100%"
                    height="100%"
                  />
                  <Image
                    src={`${content.image_2.data.attributes.url}`}
                    alt="image 2"
                    objectFit="cover"
                    width="100%"
                    height="100%"
                  />
                </div>
              );
            case "ComponentContentTypesVirtualTour":
              return (
                <VirtualTourScript
                  overlay
                  url={content.url}
                  imgUrl={`${content.image.data.attributes.url}`}
                  alt={`${process.env.REACT_APP_BACKEND_URL}${content.image.data.attributes.alternativeText}`}
                />
              );
            default:
              return null;
          }
        })()}
      </Box>
    );
  });
}
