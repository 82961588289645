import React, { useRef, useState } from "react";
import { Box, Image } from "@chakra-ui/react";
import ContentLoader from "../animations/contentLoader";
import VideoPlayer from "./videoPlayer";
import { useTranslation } from "react-i18next";
import { splitMetaDataByLang } from "../../utils/globalFunctions";

export default function ImageWrapper({
  onClick,
  icon,
  imgUrl,
  cover,
  isActive,
  loaderH,
  alt,
  noBg,
}) {
  const { i18n } = useTranslation();
  const language = i18n.language;

  const elementRef = useRef();
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = isActive ? onClick : null;
  const handleMouseEnter = isActive ? () => setIsHovered(true) : null;
  const handleMouseLeave = isActive ? () => setIsHovered(false) : null;

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <Box
      h={"100%"}
      cursor={isActive ? "pointer" : "default"}
      position={"relative"}
      onClick={handleClick}
      bg={noBg ? "none" : "black"}
      overflow="hidden"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <>
        {isLoading && <ContentLoader h={loaderH} loading={isLoading} />}
        <Image
          alt={splitMetaDataByLang(alt, language)}
          ref={elementRef}
          bg={"portfolioBg"}
          onLoad={() => {
            handleImageLoad();
          }}
          display={isLoading ? "none" : "block"}
          src={`${imgUrl}`}
          h={"100%"}
          w={"100%"}
          objectFit={cover ? "cover" : "contain"}
          transition="transform 0.4s ease-in-out, opacity 0.4s ease-in-out"
          transform={isHovered ? "scale(1.1)" : "scale(1)"}
          _hover={isActive ? { opacity: 0.4 } : {}}
        />
      </>

      <Box pointerEvents={"none"}>{icon}</Box>
    </Box>
  );
}
